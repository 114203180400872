.casama-shadow {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 98;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.casama-overlay {
    position: fixed;
    z-index: 99;
    top: 400;
    flex: 1;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    text-shadow: none;
    color: black;
}

.red-text {
    color: red;
}

:root {
  /* when changing also change colors.ts */
  /* constants */

  /* colors */
  --color1: #ff71ce;
  --color2: #01cdfe;
  --color3: #05ffa1;
  --color4: #b967ff;
  --color5: #fffb96;
  --color_debug: #ff0000;

  /* backgrounds */
  --bkgnd1: linear-gradient(to bottom right, var(--color2), var(--color4));
  --bkgnd2: linear-gradient(to bottom right, var(--color4), var(--color2));
  /* --bkgnd3: linear-gradient(to bottom right, var(--color1), var(--color3), var(--color1)); */
  --bkgnd3: linear-gradient(to bottom right, var(--color1), var(--color4), var(--color2));

  /* text shadows */
  --txtshad1: 2px 2px 3px black;

  /* box shadows */
  --boxshad1: 5px 5px 3px black;
  --boxshad2: 5px 5px 3px var(--color3);

  /* default (root) styles */
  color: var(--color5);
  background-image: var(--bkgnd1);
  text-shadow: var(--txtshad1);
}

.App {
  text-align: center;
  background-image: url('assets/curve_grid.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Main {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.contract-panel {
  padding: 10px;
  margin: 20px;
  display: flex;
  background-image: var(--bkgnd1);
}

.contract-panel-function-list {
  padding: 10px;
  margin: 20px;
  flex: 1;
  background-image: var(--bkgnd1);
}

.contract-panel-body {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.contract-panel-function-inputs,
.contract-panel-function-outputs {
  background-image: var(--bkgnd1);
  padding: 10px;
  margin: 20px;
  flex: 1;
}

.generate-solution-sig-tool {
  text-align: start;
  background-image: var(--bkgnd1);
}

.footer {
  display: flex;
  align-items: center;
}

.footer-element {
  flex: 1;
  padding: 5px;
  align-items: center;
}

.challenge-table-navigator {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.challenge-action-buttons {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
}

.scoreboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.challenges-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.challenges-shop-layout-view-parent {
  width: 95%;
  align-self: center;
}

#widebtn {
  width: 100%;
  margin-top: 5px;
}

.function-option {
  padding: 10px;
  background-image: var(--bkgnd1);
  flex: 1;
  text-align: center;
}

#special-function-option {
  background-color: var(--color1);
  box-shadow: var(--boxshad1);
}

.function-option:hover,
#special-function-option:hover,
button:hover {
  background-color: var(--color3);
  box-shadow: var(--boxshad2);
  background-image: var(--bkgnd2);
}

.challenge-modal,
.sponsor-modal,
.large-challenge-modal {
  background-image: var(--bkgnd1);
  padding: 10px;
  margin: 20px;
  position: fixed;
  z-index: 98;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
}

.sponsor-modal {
  top: 0;
  right: 0;
}

.challenge-modal {
  top: 0;
  left: 0;
}

.challenge-modal-child {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.wide-spacer,
.column-block {
  flex: 1;
}

.large-challenge-modal {
  align-self: center;
  display: flex;
  flex-direction: row;
}

.large-challenge-modal-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px;
}

.token-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: var(--bkgnd1);
  padding: 10px;
  margin: 20px;

}



.token-view-list-ids {
  display: flex;
  flex-wrap: wrap;
  background-image: var(--bkgnd1);
  border-color: var(--color1);
  padding: 10px;
  margin: 20px;
}

.token-view-single,
.token-view-count-display {
  background-image: var(--bkgnd1);
  padding: 5px;
  margin: 5px;
  color: var(--color3);
  text-shadow: var(--txtshad1);
}

.token-view-single:hover,
.token-view-count-display:hover {
  background-image: var(--bkgnd2);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.challenges-display-control-area {
  padding: 10px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-image: var(--bkgnd1);
}

.challenges-display-control {
  padding: 5px;
  margin: 5px;
  flex: 1;
  background-image: var(--bkgnd1);
}

.challenges-section {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.fancy-title-wrap {
  display: flex;
}

.fancy-title {
  flex: 1;
  margin: 10px;
}

.a {
  color: var(--color5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-shadow: var(--txtshad1);
}

.small-title {
  text-shadow: var(--txtshad1);
  margin: 0px;
  padding: 0px;
}

.btn-type-2 {
  background-image: var(--bkgnd3);
  color: var(--color5);
  border-color: var(--color1);
  border-style: none;
  flex: 1;
  padding: 10px;
  box-shadow: var(--boxshad1);
}

table {
  border-color: var(--color1);
  border-collapse: collapse;
}

tr:hover {
  background-color: var(--color3);
}

td:hover {
  background-color: var(--color4);
}

a:link {
  color: var(--color3);
}

a:visited {
  color: var(--color5);
}

a:hover {
  background-color: var(--color3);
  color: var(--color4);
}

#link-hover-style-exception:hover,
.direct-to-market-section:hover {
  color: var(--color5);
}

.direct-to-market-section {
  display: flex;
  flex-direction: row;
  /* background-image: url('assets/backgroundimage.svg'); */
  /* background-image: url('assets/curve_grid.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}

.direct-to-market-section:after {
  background-color: var(--color4);
  background-size: cover;
  position: absolute;
  opacity: 0.5;
}

#link-hover-style-exception {
  font-size: 100px !important;
}

.market-title {
  padding: 30px;
  font-size: x-large;
  flex: 1;
}

.market-title:hover {
  animation: shake 1s infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

.placeholder {
  flex: 2;
  display: flex;
}

.placeholder-inner {
  flex: 1;
  border-style: dashed;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

button:disabled {
  background-color: var(--color4);
}

button:disabled:hover {
  box-shadow: var(--boxshad1);
  color: var(--color5);
}

h1 {
  font-size: 67px;
}

.single-challenge {
  padding: 10px;
  margin: 10px;
  border-color: var(--color1);
  background-image: var(--bkgnd1);
  box-shadow: var(--boxshad1);
}

.nav-btn:hover {
  color: var(--color1);
}

button,
.function-option {
  text-shadow: var(--txtshad1);
}

/* button:hover,
.function-option:hover {
  text-shadow: 0px 0px 0px;
} */

.error-state {
  background-color: black;
}

.warning {
  background-color: #FF4444;
  margin: 20px;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  width: 250px;
  text-align: left;
  opacity: 0.75;
}

/* .chain-indicator {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
} */

/* 
  source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
  August 8th 2022
  William Doyle
*/
.back-to-top {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 50px;
  background-image: var(--bkgnd1);
  color: white;
  cursor: pointer;
  border: none;
}

/* 
  source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
  August 8th 2022
  William Doyle
*/
.back-to-top:hover {
  background-image: var(--bkgnd2);
}

/* green glowing border */
.token-view,
.back-to-top,
.challenges-display-control-area {
  border: 2px solid var(--color3);
  border-radius: 7px;
  box-shadow: 0 0 20px var(--color3);
}

.process-solution {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  background-color: var(--color4);
  border-radius: 5px;
  width: 80%;
      box-shadow: 0 0 0 99999px rgba(0, 0, 0, .5);
}

.process-solution-header,
.process-solution-body {
  border: 2px solid var(--color3);
  border-radius: 7px;
  box-shadow: 0 0 20px var(--color3);
}

.process-solution-header {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.process-solution-header-close {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: var(--color_debug);
}

.process-solution-body {
  /* display:flex; */
  /* justify-content: space-between; */
  margin: 10px;
  padding: 10px;
}

/* .process-solution-parent {
  width: 100%;
  height: 100%;
  background-color: black;

  z-index: 10000;
} */
